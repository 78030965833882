import { get } from 'vuex-pathify'
import { configs as itemsConfigs } from '~/models/items/index'

export default {
  props: {
    item: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },

    parent: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },

  data: () => ({
    itemsConfigs,
  }),

  computed: {
    isAdminMode: get('isAdminMode'),

    hasBackground() {
      return this.item.props.bgColor?.color || this.item.props.bgColor?.gradient
    },

    hasBoxShadow() {
      return this.item.props.boxShadow
    },
  },

  methods: {
    findChild(alias) {
      return this.item.items.find(item => item.alias === alias)
    },
  },
}
