import { configs as blocksConfigs } from '~/models/blocks/index'

export default {
  props: {
    index: {
      type: Number,
      default: null,
    },

    rowPosition: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      blocksConfigs,
    }
  },

  computed: {
    align() {
      return this.item.props.align
    },

    anySectionBlockHasBg() {
      return (
        this.isHalf && this.parent.items.some(item => item.props.bgColor?.color)
      )
    },

    isHalf() {
      return this.item.props.width !== '1:1'
    },

    hasSibling() {
      return this.parent.items?.length > 1 ?? false
    },

    parentHasBgColor() {
      const { color, gradient } = this.parent.props.bgColor
      return color || gradient
    },

    headlineItem() {
      return this.item.items.find(item => item.name === 'Headline')
    },

    textItem() {
      return this.item.items.find(item => item.name === 'HeaderBodyText')
    },

    emailFormItem() {
      return this.item.items.find(item => item.name === 'EmailForm')
    },

    socialLinksItem() {
      return this.item.items.find(item => item.name === 'SocialLinks')
    },

    featuresItems() {
      const items =
        this.item.items?.filter(item => item.name === 'Feature') ?? []

      if (items.length) {
        return items
      }

      return null
    },

    btnItems() {
      const items = this.item.items?.filter(item => item.name === 'Btn') ?? []

      if (items.length) {
        return items
      }

      return []
    },
  },
}
