<template>
  <Item :item="item" :parent="parent">
    <Component
      :is="isAdminMode ? 'SortableList' : 'div'"
      v-model="subItems"
      class="flex flex-wrap gap-x-6 items-center w-full gap-y-[1.75em]"
      :class="wrapperClasses"
      v-bind="sortableListBindings"
      data-tour-step="ContentBlock"
    >
      <Component
        :is="isAdminMode ? 'SortableItem' : 'div'"
        v-for="(subItem, idx) in subItems"
        :key="subItem.uid"
        :index="idx"
        :class="{ 'w-full': !['Btn', 'ImageItem'].includes(subItem.name) }"
      >
        <Component
          :is="component(subItem)"
          v-bind="bindings(subItem)"
          :item="subItem"
          :parent="item"
          :class="[subItemClasses(subItem, idx)]"
        />
      </Component>
    </Component>
  </Item>
</template>

<script>
import { dispatch } from 'vuex-pathify'
import itemMixin from '@/mixins/item'
import blockMixin from '@/mixins/block'

export default {
  name: 'ContentBlock',

  components: {
    SortableList: () => import('@/components/SortableList'),
    SortableItem: () => import('@/components/SortableItem'),
    Item: () => import('@/components/Item'),
    Btn: () => import('@/components/Items/Btn'),
    HorizontalLine: () => import('@/components/Items/HorizontalLine'),
    ImageItem: () => import('@/components/Items/ImageItem'),
    Content: () => import('@/components/Items/Content'),
    Feature: () => import('@/components/Items/Feature'),
    Card: () => import('@/components/Items/Card'),
    SocialLinks: () => import('@/components/Items/SocialLinks'),
    RichList: () => import('@/components/Items/RichList'),
    EmailForm: () => import('@/components/Items/EmailForm'),
    Headline: () => import('@/components/Items/Headline'),
    // Container: () => import('@/components/Items/Container'),
    Quote: () => import('@/components/Items/Quote'),
    Embed: () => import('@/components/Items/Embed'),
  },

  mixins: [blockMixin, itemMixin],

  computed: {
    imageItem() {
      return this.item.items.find(item => item.name === 'ImageItem')
    },

    subItems: {
      get() {
        return this.item.items
      },

      set(val) {
        dispatch('page/update', {
          mutation: 'SET_BLOCK_ITEMS',
          item: { uid: this.item.uid }, // parent
          value: val,
        })
      },
    },

    sortableListBindings() {
      if (!this.isAdminMode) {
        return
      }

      return {
        useDragHandle: true,
        axis: 'y',
        lockAxis: 'y',
        appendTo: `[data-uid="${this.item.uid}"]`,
      }
    },

    wrapperClasses() {
      const align = {
        left: 'justify-start mr-auto',
        center: 'justify-center mx-auto',
        right: 'justify-end ml-auto',
      }[this.align]

      return [
        align,
        {
          'lg:w-2/3': !this.isHalf,
        },
      ]
    },
  },

  methods: {
    component(item) {
      if (['HeaderBodyText'].includes(item.name)) {
        return 'Content'
      }

      return item.name
    },

    subItemClasses(item, idx) {
      let yMargin

      if (this.subItems.length > 1 && item.name === 'Headline') {
        if (this.subItems[idx + 1]?.name === 'HeaderBodyText') {
          yMargin = '-mb-3'
        }
      }

      const xMargin = {
        left: 'mr-auto',
        center: 'mx-auto',
        right: 'ml-auto',
      }[this.align]

      const itemClasses = {
        ImageItem: 'relative shrink-0',
      }

      return [itemClasses[item.name], xMargin, yMargin].join(' ')
    },

    bindings(item) {
      const bindings = {
        Feature: {
          horizontal: true,
        },

        ImageItem: {
          appearance: item.props.appearance,
          imgClass: { 'rounded-lg': true },
          dimensions: {
            width: 800,
            height: 450,
          },
        },
      }
      return bindings[item.name] ?? {}
    },
  },
}
</script>
